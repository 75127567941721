import React, { useState } from "react";
import { db } from "../../firebase";
import { doc, setDoc, collection, Timestamp } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MdError } from "react-icons/md"
import { defaultRoute } from "defaultData";
import Logo from "../../resources/plytol_logo.png";
import "../../styles/signin.css";

export default function HostSignUpPage({ setIsLoading, setPageState, setSession }) {
    // function to shuffle an array
    // source: https://stackoverflow.com/a/2450976
    function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    // error message state variable
    const [error, setError] = useState();

    // number of players state variable
    // set to 2 initially
    const [numPlayers, setNumPlayers] = useState(2);

    // formData state object
    const [formData, setFormData] = useState({
        "teamName": "",
        "Players": ["", "", "", ""],
        "isAdmin": true
    });

    // function to handle submitting data
    // sanity checks data, then submits to database if valid
    const submitData = async (evt) => {
        evt.preventDefault();

        // array of non-null player names
        const validPlayers = [];
        formData["Players"].forEach((item) => {
            // get all players in the array, trim them, then add them to the array
            // if they are non-null
            const sanitisedItem = item.trim();
            if (sanitisedItem) {
                validPlayers.push(sanitisedItem);
            }
        });

        const santisedTeamName = formData["teamName"].trim().toLowerCase();

        if (validPlayers.length < 2) {
            setError("Must choose at least 2 players");
        } else if (validPlayers.length !== numPlayers) {
            setError("Cannot have empty player names");
        } else if (santisedTeamName === "") {
            setError("Please enter team name");
        } else {
            // otherwise valid
            setError(); // unset error for next time

            setIsLoading(true);

            try {
                const auth = getAuth();
                try {
                    await signInAnonymously(auth);
                } catch (err) {
                    console.log(`${error.code}: ${error.message}`);

                    const analytics = getAnalytics();
                    logEvent(analytics, 'exception', { description: error.message });

                    return;
                }

                // initialise array of scores as array of ten 0s
                // this array is indexed by (hole - 1)
                const scoreArr = [];
                for (let i = 0; i < 10; i++) {
                    scoreArr.push(0);
                }

                // generate new game
                // do this before adding the team to the db so we don't need to set the data twice 
                const newGameRef = doc(collection(db, "Games"));
                const gameID = newGameRef.id;

                // add team to db without a reference so it gets an auto-id
                // no need to check if team name is valid or not
                // const teams = collection(db, "Teams");
                const newTeamRef = doc(collection(db, "Teams"));
                const teamID = newTeamRef.id;

                const newTeam = {
                    teamName: santisedTeamName,
                    Players: validPlayers,
                    Score: scoreArr,
                    PenaltyPoints: 0,
                    gameID: gameID,
                    isHost: true
                }

                await setDoc(newTeamRef, newTeam);

                const defaultRoutePubsOnly = {
                    "Pubs": defaultRoute["Pubs"],
                };

                const numArr = [];
                for (let i = 0; i < 9; i++) {
                    numArr.push(i);
                }
                shuffle(numArr);

                for(let i = 0; i < 9; i++) {
                    defaultRoutePubsOnly["Pubs"][i].CurrentChallenge = numArr[i];
                }

                const newRouteRef = doc(collection(db, "Routes"));
                await setDoc(newRouteRef, defaultRoutePubsOnly);

                // generate random 6-digit number as game code
                const gameCode = Math.floor(Math.random() * 900000) + 100000;

                // add new team to new game's list of teams
                const newGameData = {
                    Teams: [{
                        "teamID": teamID,
                        "teamName": santisedTeamName,
                        "Score": 0,
                        "PenaltyPoints": 0
                    }],
                    GameCode: gameCode,
                    CurrentPub: 0,
                    StartTime: Timestamp.now(),
                    RouteID: newRouteRef.id,
                    ChallengesEnabled: true,
                }

                await setDoc(newGameRef, newGameData);

                // set session with team ID and team name
                setSession({
                    teamID: teamID,
                    teamName: santisedTeamName,
                    gameID: gameID,
                    gameCode: gameCode,
                    score: 0,
                    isHost: true
                });

                const analytics = getAnalytics();
                logEvent(analytics, 'sign_up', {
                    type: "host"
                });

                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                console.log(err);

                const analytics = getAnalytics();
                logEvent(analytics, 'exception', { description: err.message });
            }
        }
    }

    // function to handle changing form data
    const handleChange = (event) => {
        const { value, name } = event.target;

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    // specifically handle the changing of player name data
    function handlePlayer(event, index) {
        const { value, name } = event.target;

        const newPlayers = formData["Players"].map((player, i) => {
            if (i === index) {
                return value;
            } else {
                return player;
            }
        });

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: newPlayers
            }
        });
    }

    // code for dynamically creating input boxes for variable number of players
    // unfortunately this has to be done with a for loop rather than a .map, 
    // since we are looping numPlayers times and not formData["Players"].length times
    const textboxes = [];
    for (let i = 0; i < numPlayers; i++) {
        textboxes.push(
            <React.Fragment key={i}>
                <p className="signin--label">PLAYER {i + 1}</p>
                <input
                    className="signin--input"
                    key={i}
                    id={`player${i + 1}`}
                    name="Players"
                    value={formData["Players"][i]}
                    type="text"
                    onChange={e => handlePlayer(e, i)}
                    maxLength="20"
                />
                <p className="input--limit--text">{formData["Players"][i].length}/20</p>
            </React.Fragment>
        );
    }

    // function to change value of numPlayers variable
    const changePlayerNum = (val) => {
        const playerLowerBound = 2;
        const playerUpperBound = 4;

        if ((numPlayers + val) < playerLowerBound || (numPlayers + val) > playerUpperBound) {
            // do nothing
            // this should never fire but it's here just in case
            setError("Number of players must be between 2 and 4")
        } else {
            setFormData(prevFormData => {
                let newPlayers = prevFormData["Players"];
                newPlayers[numPlayers + val] = ""; // unset any previously set (now invalid) data
                return {
                    ...prevFormData,
                    Players: newPlayers
                }
            });

            // set this after since previous relies on prevNum
            // so there'd be a race condition otherwise
            setNumPlayers(prevNum => prevNum + val);
        }
    }

    return (
        <div className="signin--page">
            <img src={Logo} alt="Plytol Ltd Logo" />
            <h1 className="signin--title">HOST GAME</h1>
            <div className="signin--form">
                {textboxes}
                <span className="signin--numbuttons">
                    {
                        numPlayers < 4 &&
                        <button
                            onClick={() => changePlayerNum(1)}
                            disabled={numPlayers >= 4}>
                            Add Player
                        </button>
                    }
                    {
                        numPlayers > 2 &&
                        <button
                            onClick={() => changePlayerNum(-1)}
                            disabled={numPlayers <= 2}>
                            Remove Player
                        </button>
                    }
                </span>
                
                <br />

                <p className="signin--label">TEAM NAME</p>
                <input
                    className="signin--input"
                    id="teamname"
                    type="text"
                    name="teamName"
                    value={formData["teamName"]}
                    maxLength="20"
                    onChange={e => handleChange(e)} />
                <p className="input--limit--text">{formData["teamName"].length}/20</p>
                {error &&
                    <div className="signin--error--container">
                        <h1 className="signin--error"><MdError className="signin--error--icon" />{error}</h1>
                    </div>
                }
                <span className="signin--action--container">
                    <button
                        className="signin--submit"
                        onClick={(event) => submitData(event)}>
                        SUBMIT
                    </button>
                    <button
                        className="signin--back"
                        onClick={() => setPageState(0)}>
                        BACK
                    </button>
                </span>
            </div>

        </div>
    )
}
