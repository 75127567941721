import React, { useEffect, useState } from "react";
import HostSignUpPage from "./HostGamePage";
import AdminSignIn from "./AdminSignIn";
import JoinGameSignUp from "./JoinGamePage";
import ReconnectPage from "./ReconnectPage";
import logo from "resources/plytol_logo.png";
import "../../styles/auth.css";

export default function AuthPage({ setIsLoading, setSession, isDisclaimer, setDisclaimer }) {
    const isOffSeason = false;

    // 0 = initial, 1 = sign up, 2 = sign in, 3 = admin
    const [pageState, setPageState] = useState(0);

    let page;
    switch (pageState) {
        default:
        case 0:
            page = <>
                <span className="auth--title--container">
                    <h1 className="auth--title">PUB GOLF</h1>
                </span>
                <div className="auth--header">
                    <p>Powered by</p>
                    <img
                        src={logo}
                        alt="Header"
                    />
                </div>
                <div className="auth--button--container">
                    <button
                        onClick={() => setPageState(1)}
                        className="auth--button">
                        HOST GAME
                    </button>
                    <button
                        onClick={() => setPageState(2)}
                        className="auth--button">
                        JOIN GAME
                    </button>
                    <button
                        onClick={() => setPageState(6)}
                        className="auth--reconnect">
                        RECONNECT
                    </button>
                </div>
            </>
            break;
        case 1:
            page = <HostSignUpPage setIsLoading={setIsLoading} setPageState={setPageState} setSession={setSession} />
            break;
        case 2:
            page = <JoinGameSignUp setIsLoading={setIsLoading} setSession={setSession} setPageState={setPageState} />
            break;
        case 3:
            page = <AdminSignIn setIsLoading={setIsLoading} setSession={setSession} setPageState={setPageState} />
            break;
        case 4:
            page = <div className="auth--disclaimer">
                <h1>Plytol Ltd alcohol disclaimer</h1>
                <p>Plytol Ltd does not accept any responsibility or
                    liability for any harm caused as a result of alcohol consumption.
                    Please follow the rules and policies for each establishment featured on the app.
                    Each establishment reserves the right to refuse service and/or entry.
                    Drinking is prohibited for those under the age of 18 under UK law.
                    CheltPubGolf is a social game and should be used as such.
                    If you need to talk to someone about drinking, visit <a href="https://www.drinkaware.co.uk/">https://www.drinkaware.co.uk/</a>
                </p>
                <button onClick={() => {
                    setDisclaimer(true);
                    setPageState(0);
                }}>
                    Accept
                </button>
            </div>;
            break;
        case 5:
            page = <div className="auth--soon">
                <h1>COMING SOON</h1>
                <h2>01/07/2023</h2>
            </div>
            break;
        case 6:
            page = <ReconnectPage setIsLoading={setIsLoading} setSession={setSession} setPageState={setPageState} />;
            break;
    }

    useEffect(() => {
        if (isOffSeason) {
            setPageState(5);
        } else if (!isDisclaimer) {
            // show disclaimer
            setPageState(4);
        }
    }, [isDisclaimer, isOffSeason])

    return (
        <div className="auth--container">
            {page}
        </div>
    )
}
