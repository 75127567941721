import React from "react";

export default function RouteTracker({ CurrentPub }) {

    const activeBalls = [];
    for (let i = 0; i < CurrentPub; i++) {
        activeBalls.push(<div key={i} className="route--tracker--active--circle"></div>);
    }

    const inactiveBalls = [];
    for (let i = CurrentPub; i < 9; i++) {
        activeBalls.push(<div key={i} className="route--tracker--inactive--circle"></div>);
    }

    return (
        <div className="header--route--container">
            <span className="route--tracker--inactive--icons">
                {activeBalls}
                {inactiveBalls}
            </span>
        </div>
    )
}