import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { uuidv4 } from "@firebase/util";
import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { defaultRoute } from "defaultData";
import BackButton from "components/BackButton";

export default function SetRoutePage({ setIsLoading, changeState }) {
    const [challengesEnabled, setChallengesEnabled] = useState(true);

    const [isOpen, setIsOpen] = useState([false, false, false, false, false, false, false, false, false]);

    const [hasChanged, setHasChanged] = useState(false);

    const [pubData, setPubData] = useState([]);
    const fetchPubs = useCallback(async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameData = gameSnap.data();
        const routeID = gameData["RouteID"];

        const challengesEnabled = gameData["ChallengesEnabled"];
        setChallengesEnabled(challengesEnabled);

        const routeSnap = await getDoc(doc(db, "Routes", routeID));
        const pubsArr = routeSnap.data()["Pubs"];

        const storage = getStorage();

        const pubsArrWithImages = [];
        for (let i = 0; i < pubsArr.length; i++) {
            const item = pubsArr[i];

            const imageRefURL = `pub-images/${item["ImageURL"]}`;

            const imageRef = ref(storage, imageRefURL);

            const imageURL = await getDownloadURL(imageRef);

            pubsArrWithImages.push({
                ...item,
                "ImageToDisplay": imageURL
            });
        }

        setPubData(pubsArrWithImages);

        setIsLoading(false);
    }, [setIsLoading]);

    useEffect(() => {
        fetchPubs();
    }, [fetchPubs]);

    const changeIndex = (index, valueChanged) => {
        const oldPos = index; // old position
        const newPos = oldPos + valueChanged; // new position

        if (newPos < 0 || newPos >= pubData.length) {
            return; // do nothing
        }

        const movedItem = pubData[oldPos];

        const newData = pubData.filter(item => item !== movedItem);
        newData.splice(newPos, 0, pubData[oldPos]);

        setPubData(newData);

        setHasChanged(true);
    }

    const handleChange = (event, index) => {
        const { value, name, type, checked } = event.target;

        setHasChanged(true);
        const newData = pubData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    [name]: type === "checkbox" ? checked : value
                }
            } else {
                return item;
            }
        });

        setPubData(newData);
    }

    const submitChanges = async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameID = session["gameID"];

        const gameSnap = await getDoc(doc(db, "Games", gameID));
        const gameData = gameSnap.data();
        const routeID = gameData["RouteID"];

        const pubDataWithProperImageURLs = pubData.map((item) => {
            return {
                ...item,
                "ImageToDisplay": "",
                "ImageURL": item["ImageURL"]
            };
        });

        const newData = {
            "Pubs": pubDataWithProperImageURLs
        }

        await setDoc(doc(db, "Routes", routeID), newData);

        setHasChanged(false);
        setIsLoading(false);
    }

    const handleOpenToggle = (index) => {
        setIsOpen(prevData => {
            const newData = prevData.map((item, i) => {
                if (i === index) {
                    return !item;
                } else {
                    return item;
                }
            });
            return newData;
        });
    }

    const uploadNewImage = async (event, index) => {
        setIsLoading(true);

        const file = event.target.files[0];

        const filename = uuidv4();

        const storage = getStorage();
        const storageRef = ref(storage, `pub-images/${filename}`);

        await uploadBytes(storageRef, file).then(() => {
            console.log("Image successfully uploaded");
        });

        const imageURL = await getDownloadURL(storageRef);

        setPubData(prevData => {
            const newData = prevData.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        "ImageToDisplay": imageURL,
                        "ImageURL": filename
                    }
                } else {
                    return item;
                }
            });

            return newData;
        });

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameID = session["gameID"];

        const gameSnap = await getDoc(doc(db, "Games", gameID));
        const gameData = gameSnap.data();
        const routeID = gameData["RouteID"];

        const pubDataWithProperImageURLs = pubData.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    "ImageToDisplay": "",
                    "ImageURL": filename
                };
            } else {
                return item;
            }
        });

        const newData = {
            "Pubs": pubDataWithProperImageURLs
        }

        await setDoc(doc(db, "Routes", routeID), newData);

        setIsLoading(false);
    }

    const routeDisplay = pubData.map((item, i) => {
        return (
            <div className="route--edit--pub" key={i}>
                <span className="route--edit--top">
                    <div className="route--edit--tab" onClick={() => handleOpenToggle(i)}>
                        <h2>{i + 1}</h2>
                        <h2>{item["PubName"]}</h2>
                        {
                            isOpen[i]
                                ? <GoTriangleDown />
                                : <GoTriangleRight />
                        }
                    </div>
                    <button
                        className="route--edit--arrow"
                        disabled={i === 0}
                        onClick={() => changeIndex(i, -1)}>
                        <AiOutlineArrowUp />
                    </button>
                    <button
                        className="route--edit--arrow"
                        disabled={i === pubData.length - 1}
                        onClick={() => changeIndex(i, 1)}>
                        <AiOutlineArrowDown />
                    </button>
                </span>
                {
                    isOpen[i] &&
                    <div className="route--edit--content">
                        <span>
                            <p>Pub Name</p>
                            <input
                                name="PubName"
                                onChange={e => handleChange(e, i)}
                                className="route--edit--large"
                                value={item["PubName"]}
                                maxLength="40" />
                        </span>
                        <span>
                            <p>Drink</p>
                            <input
                                name="Drink"
                                onChange={e => handleChange(e, i)}
                                className="route--edit--large"
                                value={item["Drink"]}
                                maxLength="40" />
                        </span>
                        <span>
                            <p>Non-alcoholic Drink</p>
                            <input
                                name="NADrink"
                                onChange={e => handleChange(e, i)}
                                className="route--edit--large"
                                value={item["NADrink"]}
                                maxLength="40" />
                        </span>
                        <span>
                            <p>Par</p>
                            <input
                                type="number"
                                name="Par"
                                onChange={e => handleChange(e, i)}
                                className="route--edit--small"
                                value={item["Par"]} />
                        </span>
                        <p>NOTE: -1 represents NO PAR</p>
                        <span>
                            <p>Water Hazard?</p>
                            <input
                                name="isWaterHazard"
                                onChange={e => handleChange(e, i)}
                                type="checkbox"
                                checked={item["isWaterHazard"]} />
                        </span>
                        <span>
                            <p>Current Image</p>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={item["ImageToDisplay"]}>
                                Image
                            </a>
                        </span>
                        <span>
                            <input
                                type="file"
                                name="ImageURL"
                                onChange={e => uploadNewImage(e, i)}
                            />
                        </span>
                        {
                            challengesEnabled &&
                            <span>
                                <p>Challenge</p>
                                <p>{defaultRoute["Challenges"][item["CurrentChallenge"]]["challengeName"]}</p>
                            </span>
                        }
                    </div>
                }
            </div>
        );
    });

    const knuthShuffle = (array) => {
        let currentIndex = array.length;
        let randomIndex;

        while (currentIndex > 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const changeChallengesEnabled = async () => {
        setIsLoading(true);
        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameID = session["gameID"];

        await setDoc(doc(db, "Games", gameID), { "ChallengesEnabled": !challengesEnabled }, { merge: true });

        setChallengesEnabled(prevVal => {
            return !prevVal;
        });

        if (!challengesEnabled === true) {
            shuffleChallenges();
        } else {
            setIsLoading(false);
        }
    }

    const shuffleChallenges = async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameID = session["gameID"];

        const indexArr = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        knuthShuffle(indexArr);

        const newPubData = pubData.map((item, i) => {
            return {
                ...item,
                "CurrentChallenge": indexArr[i]
            }
        });

        const gameSnap = await getDoc(doc(db, "Games", gameID));
        const routeID = gameSnap.data()["RouteID"];
        await setDoc(doc(db, "Routes", routeID), {
            "Pubs": newPubData,
        });

        setPubData(newPubData);

        setIsLoading(false);
    }

    return (
        <div className="set--route--page">
            <div className="back--button--container">
                <BackButton changeState={changeState} />
            </div>
            <h1>Edit Route</h1>
            <span className="challenges--toggle">
                <input
                    name="challengesEnabled"
                    type="checkbox"
                    onChange={() => changeChallengesEnabled()}
                    checked={challengesEnabled} />
                <h2>Enable Challenges</h2>
            </span>
            <button
                className="set--route--button"
                onClick={shuffleChallenges}
                disabled={!challengesEnabled}>Randomise Challenges</button>
            <p>Challenges are small, competitive games that must be done at every pub.
                These range from taking a shot to not swearing for an entire hole. Challenges will make the game harder,
                but also a lot more competitive and fun!</p>
            <hr />
            <p>WARNING: You will not be able to edit the route after you
                have started the game, so choose wisely!</p>
            {hasChanged &&
                <button
                    className="set--route--button"
                    onClick={() => submitChanges()}>
                    Submit
                </button>
            }
            <div className="edit--route--container">
                {routeDisplay}
            </div>
        </div>
    )
}
