import React, { useCallback, useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, setDoc, getDoc, deleteDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { AiOutlineReload } from "react-icons/ai";
import { BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs"
import BackButton from "components/BackButton";

// this file is massive so I'm gonna split it out a bit

export default function AdminPage({ setIsLoading, changeState, setSession }) {
    // --- STATE ---
    // state array containing team data for doing admin things with
    const [teamData, setTeamData] = useState([]);

    // state variable keeping track of current pub locally
    const [currentPub, setcurrentPub] = useState(0);

    // --- FETCHING DATA ---
    // functions for fetching data from firestore

    // async function to get all teams and store them in teamData
    const fetchTeams = useCallback(async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameObj = gameSnap;
        const gameData = gameObj.data();
        const teamsList = gameData["Teams"];

        // map the fetched data and add some new fields
        // these fields handle stuff locally and won't be pushed to the db
        // IsEditing is a bool representing if the team name is being edited locally or not
        // prevName is the name of the team upon the last fetch
        // this is used to fetch the old document and use its values in the new document when submitted
        // penalty is a number value representing the penalty to apply when penalities are submitted
        const newData = teamsList.map((item) => {
            return {
                ...item,
                "IsEditing": false,
                "prevName": item["teamName"],
                "Penalty": 0,
                "isRemovingPenalty": false
            };
        });

        setTeamData(newData);

        setIsLoading(false);
    }, [setIsLoading]);

    // function to get current pub from database
    const fetchCurrentPub = useCallback(async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameData = gameSnap.data();
        const currentPub = gameData["CurrentPub"];

        setcurrentPub(currentPub);

        setIsLoading(false);
    }, [setIsLoading]);


    // --- HANDLERS ---
    // functions to handle local state changes

    // function to handle changing team name
    const handleteamNameChange = (event) => {
        const { name, value } = event.target;

        // const session = JSON.parse(localStorage.getItem("userSession"));
        // let prevData = false;

        setTeamData(prevTeamData => {
            // loop through all old data
            const newData = prevTeamData.map((item, index) => {
                if (index === parseInt(name)) {
                    // the input box's name stores the index to change
                    // so if they're equal this is the one to update
                    return {
                        ...item,
                        "teamName": value
                    };
                } else {
                    // otherwise, no need to change anything
                    return item;
                }
            });

            // set old data to updated data
            return newData;
        });
    }

    // function to handle toggling editing the name of a team
    const handleEditMode = async (teamName) => {
        setTeamData(prevTeamData => {
            const newData = prevTeamData.map((item) => {
                if (item["teamName"] === teamName) {
                    if (item["IsEditing"] === true) {
                        // already in edit mode, so must be exiting edit mode
                        // hence submit the new data
                        updateteamName(item["prevName"], item["teamName"]);
                        return {
                            ...item,
                            "prevName": item["teamName"],
                            "IsEditing": false
                        };
                    } else {
                        // otherwise, must be entering edit mode
                        return {
                            ...item,
                            "IsEditing": true
                        };
                    }
                } else {
                    // otherwise, unset editing mode
                    return {
                        ...item,
                        "IsEditing": false
                    };
                }
            });

            // set to new data
            return newData;
        });
    }

    // function to handle changing the penalty being applied to a given team
    const handlePenaltyChange = (event) => {
        const { name, value } = event.target;

        setTeamData(prevTeamData => {
            const newData = prevTeamData.map((item) => {
                if (item["teamName"] === name) {
                    return {
                        ...item,
                        "Penalty": value
                    };
                }
                return item;
            });
            return newData;
        });
    }

    const handleRemovePenalty = (event) => {
        const { name, checked } = event.target;

        setTeamData(prevTeamData => {
            const newData = prevTeamData.map((item) => {
                if (item["teamName"] === name) {
                    return {
                        ...item,
                        "isRemovingPenalty": checked
                    }
                }
                return item;
            });
            return newData;
        });
    }


    // --- UPDATING DATABASE ---
    // functions that update the database with new data

    // function to submit and update new team name in firestore
    const updateteamName = async (prevName, newName) => {
        if (prevName === newName) {
            // if there's no actual change don't do anything
            return;
        }

        // get the previous document for its data
        const session = JSON.parse(localStorage.getItem("userSession"));

        let teamToChange;
        for (let i = 0; i < teamData.length; i++) {
            const currTeam = teamData[i];
            if (currTeam["prevName"] === prevName) {
                teamToChange = currTeam;
            }
        }

        if (teamToChange === undefined) {
            console.log("ERROR - team not found");
            return;
        }

        await setDoc(doc(db, "Teams", teamToChange["teamID"]), {
            "teamName": newName
        }, { merge: true });

        const newTeamsList = teamData.map((item) => {
            let teamName;
            if (item["teamName"] === prevName) {
                teamName = newName;
            } else {
                teamName = item["teamName"];
            }

            return {
                "Score": item["Score"],
                "teamID": item["teamID"],
                "teamName": teamName,
                "PenaltyPoints": item["PenaltyPoints"]
            }
        });

        await setDoc(doc(db, "Games", session["gameID"]), {
            "Teams": newTeamsList
        }, { merge: true });

        // TODO - update session as well

        // re-fetch teams
        await fetchTeams();

        setIsLoading(false);
    }

    // function to update current pub on database
    const updateCurrentPub = async (value) => {
        setIsLoading(true);

        if (currentPub + value === 9) {
            const analytics = getAnalytics();
            logEvent(analytics, 'goal-completion');
        }

        const session = JSON.parse(localStorage.getItem("userSession"));
        await setDoc(doc(db, "Games", session["gameID"]), {
            CurrentPub: currentPub + value
        }, { merge: true });
        setcurrentPub(prevState => prevState + value);

        setIsLoading(false);
    }

    // function to delete a given team from the database
    const deleteTeam = async (teamName) => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));

        let teamID;
        const newTeamsList = [];
        for (let i = 0; i < teamData.length; i++) {
            const item = teamData[i];

            if (item["teamName"] === teamName) {
                teamID = item["teamID"];
            } else {
                newTeamsList.push({
                    "Score": item["Score"],
                    "teamID": item["teamID"],
                    "teamName": item["teamName"],
                    "PenaltyPoints": item["PenaltyPoints"]
                });
            }
        }

        await setDoc(doc(db, "Games", session["gameID"]), {
            "Teams": newTeamsList
        }, { merge: true });

        // delete given team name from db
        await deleteDoc(doc(db, "Teams", teamID));

        // refetch teams to update
        await fetchTeams();

        setIsLoading(false);
    }


    // function to submit selected penalities for each team
    const submitPenalties = async () => {
        setIsLoading(true);

        const newTeamsArr = [];
        for (let i = 0; i < teamData.length; i++) {
            const item = teamData[i];
            if (item["Penalty"] !== 0) {
                const docRef = doc(db, "Teams", item["teamID"]);
                const docSnap = await getDoc(docRef);
                const docData = docSnap.data();

                const prevPenalty = parseInt(docData["PenaltyPoints"]);

                let newPenalty;
                if (item["isRemovingPenalty"]) {
                    newPenalty = prevPenalty - parseInt(item["Penalty"]);
                } else {
                    newPenalty = parseInt(item["Penalty"]) + prevPenalty;
                }

                await setDoc(docRef, {
                    "PenaltyPoints": newPenalty
                }, { merge: true });

                newTeamsArr.push({
                    ...item,
                    "PenaltyPoints": newPenalty
                });
            } else {
                newTeamsArr.push({
                    ...item,
                });
            }
        }

        const session = JSON.parse(localStorage.getItem("userSession"));

        const teamsArr = newTeamsArr.map((item) => {
            return {
                "Score": item["Score"],
                "teamID": item["teamID"],
                "teamName": item["teamName"],
                "PenaltyPoints": item["PenaltyPoints"]
            }
        });

        await setDoc(doc(db, "Games", session["gameID"]), {
            "Teams": teamsArr
        }, { merge: true });

        setIsLoading(false);
    }

    // --- REACT STUFF ---

    // options for the penalty dropdown
    const penaltyOptions =
        <>
            <option value={0}>---</option>
            <option value={2}>Spilling Drinks (+2)</option>
            <option value={3}>Breaking WH (+3)</option>
            <option value={3}>Falling over (+3)</option>
            <option value={5}>Chundering (+5)</option>
            <option value={6}>Missing a round (+6)</option>
            <option value={10}>Scrapping (+10)</option>
        </>

    // map fetched team data to table rows
    const teams = teamData.map((item, index) => {
        return <tr key={index}>
            <td className="admin--table--teamname">
                {item["IsEditing"]
                    ?
                    <input
                        name={index}
                        type="text"
                        value={item["teamName"]}
                        onChange={handleteamNameChange}
                        maxLength="20"
                    />
                    : item["teamName"]
                }
            </td>
            <td className="admin--table--button">
                <button onClick={() => handleEditMode(item["teamName"])}>
                    <BsFillPencilFill />
                </button>
            </td>
            <td className="admin--table--button">
                <button onClick={() => deleteTeam(item["teamName"])}>
                    <BsFillTrash3Fill />
                </button>
            </td>
            <td>
                <select
                    className="admin--table--dropdown"
                    defaultValue={0}
                    name={item["teamName"]}
                    onChange={handlePenaltyChange}>
                    {penaltyOptions}
                </select>
            </td>
            <td>
                <input type="checkbox"
                    onChange={handleRemovePenalty}
                    checked={item["isRemovingPenalty"]}
                    name={item["teamName"]} />
            </td>
        </tr>
    });

    // fetch current pub and teams once on page load
    useEffect(() => {
        fetchCurrentPub();
        fetchTeams();
    }, [fetchCurrentPub, fetchTeams]);

    return (
        <div className="admin--container">
            <div className="back--button--container">
                <BackButton changeState={changeState} />
            </div>
            <h1>Admin Page</h1>
            <h2 className="admin--current">Current hole: {currentPub}</h2>
            <div className="admin--pub-buttons">
                <button
                    onClick={() => updateCurrentPub(-1)}
                    disabled={currentPub <= 0}>
                    PREV PUB
                </button>
                <button
                    onClick={() => updateCurrentPub(1)}
                    disabled={currentPub >= 9}>
                    NEXT PUB
                </button>
            </div>
            <p>Please note name changes may not take effect fully until after reloading the page.</p>
            <table className="admin--table">
                <thead>
                    <tr>
                        <th>TEAM NAME</th>
                        <th></th>
                        <th></th>
                        <th>PENALTY</th>
                        <th>SUBTRACT PENALTY</th>
                    </tr>
                </thead>
                <tbody>
                    {teamData.length === 0
                        ? <tr><td>Empty!</td></tr>
                        : teams}
                </tbody>
            </table>
            <div className="admin--penalty--buttons">
                <button className="admin--submit--penalties" onClick={() => submitPenalties()}>Submit Penalties</button>
                <button className="admin--reload" onClick={() => fetchTeams()}><AiOutlineReload /></button>
            </div>
        </div>
    )
}
