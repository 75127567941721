import React, { useEffect, useState, useCallback } from "react";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { defaultRoute } from "defaultData";
import CodeButton from "components/CodeButton";
import LeaderboardButton from "components/LeaderboardButton";
import PlaceholderImage from "resources/footerImg.png";

export default function WelcomePage({ setIsLoading, changeState, session, isNonAlc }) {
    const [gameStarted, setGameStarted] = useState(false);

    const gameCode = session["gameCode"];
    // const currentPub = JSON.parse(localStorage.getItem("userSession"))["CurrentPub"]

    const [score, setScore] = useState(0);

    const [currentPub, setCurrentPub] = useState({
        "PubName": "",
        "Drink": "",
        "NADrink": "",
        "ImageURL": ""
    });

    const [challengeData, setChallengeData] = useState({});

    const [imageURL, setImageURL] = useState("");

    // callback function to get team score
    const getScore = useCallback(async () => {
        const teamSnap = await getDoc(doc(db, "Teams", session["teamID"]));
        const teamObj = teamSnap;
        const teamData = teamObj.data();

        const scoreVal = teamData["Score"].reduce((partialSum, a) => partialSum + a, 0);
        const penaltyVal = teamData["PenaltyPoints"];

        const overallScore = scoreVal + penaltyVal;

        setScore(overallScore);
    }, [session]);

    const getCurrentPub = useCallback(async () => {
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameData = gameSnap.data();

        const pubIndex = gameData["CurrentPub"];

        if (pubIndex === 0) {
            setImageURL(PlaceholderImage);
            setIsLoading(false);
            setChallengeData(
                {
                    "challengeName": "",
                    "challengeDesc": "",
                }
            );
            return;
        }

        if (gameData["ChallengesEnabled"] === true) {
            const routeSnap = await getDoc(doc(db, "Routes", gameData["RouteID"]));
            const currChall = routeSnap.data()["Pubs"][pubIndex - 1]["CurrentChallenge"];

            const currChalData = defaultRoute["Challenges"][currChall];

            setChallengeData(currChalData);
        } else {
            setChallengeData(
                {
                    "challengeName": "",
                    "challengeDesc": "",
                }
            );
        }

        setGameStarted(true);

        const routeID = gameData["RouteID"];

        const routeSnap = await getDoc(doc(db, "Routes", routeID));
        const pubsArr = routeSnap.data()["Pubs"];
        
        const currPub = pubsArr[pubIndex - 1];

        setCurrentPub({
            "PubName": currPub["PubName"],
            "Drink": currPub["Drink"],
            "NADrink": currPub["NADrink"],
            "ImageURL": currPub["ImageURL"],
            "isWaterHazard": currPub["isWaterHazard"]
        });

        const storage = getStorage();

        const imageRefURL = `pub-images/${currPub["ImageURL"]}`;

        const imageRef = ref(storage, imageRefURL);

        getDownloadURL(imageRef)
            .then((url) => {
                setImageURL(url);
            });

        setIsLoading(false);
    }, [session, setIsLoading]);

    // fetch team's score on page load to display it
    useEffect(() => {
        setIsLoading(true);

        getScore();
        getCurrentPub();
    }, [getScore, getCurrentPub, setIsLoading]);

    return (
        <div className="welcome--container">
            {session["isHost"] &&
                <button className="welcome--admin--button" onClick={() => changeState(4)}>Admin</button>
            }
            {
                (session["isHost"] && !gameStarted) &&
                <button className="welcome--admin--button" onClick={() => changeState(5)}>Edit Route</button>
            }
            <div className="welcome--route" onClick={() => changeState(1)}>
                <img alt="Pub" src={imageURL} />
                {gameStarted
                    ?
                    <>
                        <span className="welcome--route--pub">
                            <p>CURRENT PUB</p>
                            <p>{currentPub["PubName"]}</p>
                        </span>
                        <span className="welcome--route--drink">
                            <p>DRINK TYPE</p>
                            <p>{isNonAlc ? currentPub["NADrink"] : currentPub["Drink"]}</p>
                        </span>
                        {
                            currentPub["isWaterHazard"] &&
                            <div className="welcome--route--wh">
                                <p>Water Hazard</p>
                            </div>
                        }
                    </>
                    :
                    <>
                        <span className="welcome--route--pub">
                            <p>GAME STARTING SOON</p>
                        </span>
                    </>
                }

            </div>
            {
                challengeData["challengeName"] !== "" &&
                <div className="welcome--challenge">
                    <h2>CHALLENGE: {challengeData["challengeName"]}</h2>
                    <p>{challengeData["challengeDescription"]}</p>
                    <p>Failure will result in a 2 point penalty!</p>
                </div>
            }
            <LeaderboardButton changeState={changeState} setIsLoading={setIsLoading} session={session} />
            <span className="welcome--bottom--buttons">
                <div
                    className="welcome--bottom--scoreboard"
                    onClick={() => changeState(2)}>
                    <h1>SCORE</h1>
                    <h2>{score}</h2>
                    <span className="welcome--bottom--scoreboard--add">
                        <hr />
                        <p>add</p>
                    </span>
                </div>
                <CodeButton gameCode={gameCode} />
            </span>
        </div>
    )
}
