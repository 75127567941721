import React from "react";

export default function CookieBar({ setCookiesPageActive, setHasAcceptedCookies }) {
    return (
        <div className="cookie--bar">
            <span>
                <h2>Cookies Policy</h2>
                <p>Please be aware this website uses cookies to improve user experience.</p>
                <p className="cookies--link" onClick={() => setCookiesPageActive(true)}>Click here for more information</p>
            </span>
            <button onClick={() => {
                setHasAcceptedCookies(prevVal => {
                    return !prevVal;
                });
            }}>Understood</button>
        </div>
    )
}
