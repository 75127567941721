import React, {useCallback, useEffect, useState} from "react";
import {db} from "../firebase";
import { getDoc, doc } from "firebase/firestore";

export default function LeaderboardButton({changeState, session}) {
    const [teamData, setTeamData] = useState(["", "", ""]);
    
    const fetchGame = useCallback(async() => {
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameObj = gameSnap;
        const gameData = gameObj.data();

        const teamsArr = gameData["Teams"];

        const newTeamsArr = teamsArr.map(item => {
            return {
                ...item,
                "Score": item["Score"] + item["PenaltyPoints"]
            }
        });

        newTeamsArr.sort((a, b) => a["Score"] - b["Score"]);

        if(newTeamsArr[1] === undefined) {
            newTeamsArr[1] = {
                "teamName": "empty",
                "Score": 0
            }
        }

        if(newTeamsArr[2] === undefined) {
            newTeamsArr[2] = {
                "teamName": "empty",
                "Score": 0
            }
        }
        
        const topThreeArr = [newTeamsArr[0], newTeamsArr[1], newTeamsArr[2]];

        setTeamData(topThreeArr);
    }, [session]);
    
    useEffect(() => {
        fetchGame();
    }, [fetchGame]);

    return (
        <div
            className="leaderboard-link-button"
            onClick={() => changeState(3)}>
            <span className="leaderboard--button--header">
                <h1 className="leaderboard--button--headrow">LEADERBOARD</h1>
                <hr></hr>
            </span>

            <table className="leaderboard--button--table">
                <thead>
                    <tr>
                        <th>POSITION</th>
                        <th>TEAM</th>
                        <th>POINTS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1st</td>
                        <td>{teamData[0]["teamName"]}</td>
                        <td>{teamData[0]["Score"]}</td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>{teamData[1]["teamName"]}</td>
                        <td>{teamData[1]["Score"]}</td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>{teamData[2]["teamName"]}</td>
                        <td>{teamData[2]["Score"]}</td>
                    </tr>
                </tbody>
            </table>
            <span className="leaderboard--button--footer">
                <hr></hr>
                <p>more</p>
            </span>
        </div>
    )
}