import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { AiOutlineReload } from "react-icons/ai";
import BackButton from "components/BackButton";

export default function ScoresheetPage({ setIsLoading, changeState }) {
    // state value that shows the submit button if a change has been made
    // and does not otherwise
    const [hasChanged, setHasChanged] = useState(false);

    // state array storing data about each pub
    const [pubData, setPubData] = useState([]);

    // asynchronous function to fetch data about pubs
    const fetchPubs = useCallback(async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameData = gameSnap.data();
        const routeID = gameData["RouteID"];

        const routeSnap = await getDoc(doc(db, "Routes", routeID));
        const pubsList = routeSnap.data()["Pubs"];

        // fetch team data from database using locally stored team name

        const teamRef = doc(db, "Teams", session["teamID"]);
        const teamSnap = await getDoc(teamRef);
        const teamData = teamSnap.data();

        const penalty = parseInt(teamData["PenaltyPoints"]);
        setPenaltyPoints(penalty);

        // map each table row's data to the existing item, plus
        // the score value from the fetched team data 
        const pubsWithScore = pubsList.map((item, index) => {
            return {
                ...item,
                "Score": teamData["Score"][index]
            };
        });

        // set the state array to the data handled above
        setPubData(pubsWithScore);
        setIsLoading(false);
    }, [setIsLoading]);

    // fetch pub data once on page load and then never again, unless the refresh button is hit
    useEffect(() => {
        fetchPubs();
    }, [fetchPubs]);

    // map each item of the data array to a table row
    // each row contains pub name, par, and score
    // note that score is initialised with data fetched from the db, done above
    const tableRows = pubData.map((item, index) => {
        return <tr className="scoresheet--table--row" key={index}>
            <td>{item["PubName"]}</td>
            <td>{item["Par"]}</td>
            <td>
                <input
                    className="scoresheet--table--input"
                    type="number"
                    value={item["Score"]}
                    name="Score"
                    onChange={e => handleScoreChange(e, index)}
                />
            </td>
        </tr>
    });

    // function to handle changing score field for a row
    const handleScoreChange = async (event, index) => {
        // split out event values we want for better readability
        const { target } = event;
        const { value, name } = target;

        setHasChanged(true);

        // map the new data to the old data
        const newData = pubData.map((item, i) => {
            if (i === index && value >= 0) {
                // the data we want to change is at index=index,
                // and we only want to change the values to be non-negative
                // so only run if both are true
                return {
                    ...item,
                    [name]: value
                };
            } else {
                // otherwise, don't change the current row
                return item;
            }
        });

        // set data to newly changed data
        setPubData(newData);
    }

    // function to submit score data to the database
    const submitScoreData = async () => {
        const session = JSON.parse(localStorage.getItem("userSession"));

        setHasChanged(false);
        setIsLoading(true);

        // create a single, ordered array of all scores from each pub's individual score
        const scoresArr = pubData.map(item => {
            const num = parseInt(item["Score"]);
            if (isNaN(num)) {
                return 0;
            } else {
                return parseInt(item["Score"]);
            }
        });

        // update team data in database with new scores
        const teamID = session["teamID"];

        const docRef = doc(db, "Teams", teamID);
        await setDoc(docRef, { Score: scoresArr }, { merge: true });

        const gameRef = doc(db, "Games", session["gameID"]);
        const gameSnap = await getDoc(gameRef);
        const gameObj = gameSnap;
        const gameData = gameObj.data();
        const teamArray = gameData["Teams"];

        const scoreVal = scoresArr.reduce((partialSum, a) => partialSum + a, 0);

        for (let i = 0; i < teamArray.length; i++) {
            if (teamArray[i]["teamID"] === teamID) {
                teamArray[i]["Score"] = scoreVal;
            }
        }

        await setDoc(gameRef, {
            "Teams": teamArray
        }, { merge: true });

        await fetchPubs();

        setIsLoading(false);
    }

    // function to handle changing the penalty being applied to a given team
    const handlePenaltyChange = (event) => {
        const { value } = event.target;
        setTempPenaltyPoints(value);
    }

    const submitPenalty = async () => {
        setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));

        const teamID = session["teamID"];
        const docRef = doc(db, "Teams", teamID);

        const docSnap = await getDoc(docRef);
        const docData = docSnap.data();

        // do this because technically equal score values are stored as different numbers
        // but the select options are indexed by value
        // e.g. falling over and breaking wh are both 3 points, so the latter is stored as 3.1 to differentiate them
        const sanitisedPenaltyPoints = Math.trunc(tempPenaltyPoints) + parseInt(docData["PenaltyPoints"]);

        await setDoc(docRef, { "PenaltyPoints": sanitisedPenaltyPoints }, { merge: true });

        const gameRef = doc(db, "Games", session["gameID"]);
        const gameSnap = await getDoc(gameRef);
        const teamsArr = gameSnap.data()["Teams"];

        const newTeamsArr = teamsArr.map(item => {
            if (item["teamName"] === docData["teamName"]) {
                return {
                    ...item,
                    "PenaltyPoints": sanitisedPenaltyPoints
                }
            }

            // else return item
            return item;
        });

        await setDoc(gameRef, { "Teams": newTeamsArr }, { merge: true });

        setPenaltyPoints(prevPoints => parseInt(prevPoints) + parseInt(tempPenaltyPoints));
        setTempPenaltyPoints(0);
        setIsLoading(false);
    }

    // options for the penalty dropdown
    const penaltyOptions =
        <>
            <option value={0}>--- Select one ---</option>
            <option value={2.0}>Spilling Drinks (+2)</option>
            <option value={2.1}>Missing minigame (+2)</option>
            <option value={3.0}>Breaking WH (+3)</option>
            <option value={3.1}>Falling over (+3)</option>
            <option value={5}>Chundering (+5)</option>
            <option value={6}>Missing a round (+6)</option>
            <option value={10}>Scrapping (+10)</option>
        </>

    const [penaltyPoints, setPenaltyPoints] = useState(0);
    const [tempPenaltyPoints, setTempPenaltyPoints] = useState(0);

    return (
        <div className="scoresheet--page">
            <div className="back--button--container">
                <BackButton changeState={changeState} />
            </div>
            <div className="scoresheet--container">
                <h1>Scoresheet</h1>
                <button
                    disabled={!hasChanged}
                    className="scoresheet--submit"
                    onClick={() => submitScoreData()}>
                    Submit scores
                </button>
                <table className="scoresheet--table">
                    <thead>
                        <tr className="scoresheet--table--header">
                            <th>PUB NAME</th>
                            <th>PAR</th>
                            <th>HOLE SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
                <h2 className="scoresheet--penalty--title">Penalty:</h2>
                <p>Current penalty points: {penaltyPoints}</p>
                <span className="scoresheet--penalty--container">
                    <select
                        onChange={handlePenaltyChange}
                        value={tempPenaltyPoints}>
                        {penaltyOptions}
                    </select>
                    <button
                        disabled={tempPenaltyPoints === 0}
                        onClick={submitPenalty}>
                        Submit penalty
                    </button>
                </span>
                <span className="scoresheet--penalty--reload">
                    <button
                        onClick={fetchPubs}>
                        <AiOutlineReload />
                    </button>
                </span>
            </div>
        </div>
    )
}
