export const defaultRoute = {
    "Pubs": [
        {
            "PubName": "Yates",
            "Drink": "Guinness",
            "NADrink": "Diet Coke",
            "ImageURL": "Yates.png",
            "Par": 2,
            "isWaterHazard": false,
            "CurrentChallenge": 0,
        },
        {
            "PubName": "Revolution",
            "Drink": "Vodka Cocktail",
            "NADrink": "0% Cocktail",
            "ImageURL": "Revolutions.jpg",
            "Par": 1,
            "isWaterHazard": true,
            "CurrentChallenge": 1,
        },
        {
            "PubName": "The Bank House",
            "Drink": "Double Vodka",
            "NADrink": "Fruit Juice",
            "ImageURL": "bank_house.jpg",
            "Par": 1,
            "isWaterHazard": false,
            "CurrentChallenge": 2,
        },
        {
            "PubName": "Whittle Taps",
            "Drink": "Flavoured Cider",
            "NADrink": "Lemonade",
            "ImageURL": "WhittleTaps.jpg",
            "Par": 3,
            "isWaterHazard": false,
            "CurrentChallenge": 3,
        },
        {
            "PubName": "Lounge 72",
            "Drink": "Rum Cocktail",
            "NADrink": "0% Cocktail",
            "ImageURL": "Lounge_72.jpg",
            "Par": 1,
            "isWaterHazard": true,
            "CurrentChallenge": 4,
        },
        {
            "PubName": "Bentleys",
            "Drink": "Vodka Cocktail",
            "NADrink": "0% Cola",
            "ImageURL": "Bentleys.jpg",
            "Par": 1,
            "isWaterHazard": false,
            "CurrentChallenge": 5,
        },
        {
            "PubName": "The Spectre",
            "Drink": "Red Wine",
            "NADrink": "Redbull",
            "ImageURL": "The_Spectre.jpg",
            "Par": 1,
            "isWaterHazard": false,
            "CurrentChallenge": 6,
        },
        {
            "PubName": "The Miller",
            "Drink": "Dry White Wine",
            "NADrink": "j20",
            "ImageURL": "The_Miller.png",
            "Par": 2,
            "isWaterHazard": true,
            "CurrentChallenge": 7,
        },
        {
            "PubName": "The Moon Under Water",
            "Drink": "Cocktail Pitcher",
            "NADrink": "Pint of Pepsi (each)",
            "ImageURL": "TMUW.png",
            "Par": -1,
            "isWaterHazard": false,
            "CurrentChallenge": 8,
        }
    ],
    "Challenges": [
        {
            "challengeName": "Pitch Perfect",
            "challengeDescription": "All players must sing the first song to play in the pub.",
            "challengeID": 1,
        },
        {
            "challengeName": "Hand switch",
            "challengeDescription": "Drink your drink with the opposite hand to the hand you would normally use.",
            "challengeID": 2,
        },
        {
            "challengeName": "PG-13",
            "challengeDescription": "No swearing allowed in this pub!",
            "challengeID": 3,
        },
        {
            "challengeName": "Keep it up",
            "challengeDescription": "Only empty glasses are allowed on the table.",
            "challengeID": 4
        },
        {
            "challengeName": "...TEQUILA!",
            "challengeDescription": "Drink a shot of Tequila with salt and lime.",
            "challengeID": 5
        },
        {
            "challengeName": "Jäger time",
            "challengeDescription": "Drink a JägerBomb.",
            "challengeID": 6
        },
        {
            "challengeName": "Rum Forest Rum",
            "challengeDescription": "Drink a shot of Rum.",
            "challengeID": 7
        },
        {
            "challengeName": "Bottoms up",
            "challengeDescription": "The lowest team in the leaderboard (i.e., the highest scoring) must come up with a rule that everyone must follow in this pub.",
            "challengeID": 8
        },
        {
            "challengeName": "Stand off",
            "challengeDescription": "Every player drinking in this pub must start drinking at the same time - the last to finish fails the challenge.",
            "challengeID": 9
        },
    ],
}
