// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCLCwlQc6nHLvI82sYZmmq6IpF_yStSf_o",
    authDomain: "pub-golf-app.firebaseapp.com",
    projectId: "pub-golf-app",
    messagingSenderId: "845335034133",
    appId: "1:845335034133:web:63013f5e64249e8052511c",
    storageBucket: "gs://pub-golf-app.appspot.com/"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const analytics = initializeAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);

export const storage = getStorage(firebaseApp);

export const firebaseAuth = getAuth(firebaseApp);
