import React from "react";
import { BiArrowBack } from "react-icons/bi";

export default function PPPage({setPPActive}) {
    return (
        <div>
            <div className="back--button--container">
                <div className="back--container">
                    <BiArrowBack
                        size={40}
                        className="back--icon"
                        onClick={() => setPPActive(false)} />
                </div>
            </div>
            <h1 className="pp--header">Privacy Policy</h1>
            <span className="privacy--policy">
                <h2>Last modified 07/10/2023</h2>
                <p>At Plytol Ltd, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect any data obtained through your use of the CheltPubGolf web app.</p>
                <h2>Information We Collect:</h2>
                <p>We do not manditorily collect any personal or sensitive data from users of CheltPubGolf. Personal and sensitive information includes, but is not limited to, your name, address, email address, phone number, financial details, or any other personally identifiable information.</p>
                <p>Users may optionally input personal information through CheltPubGolf - namely, the "team member names" field when hosting or joining a game. This is entirely optional and users may prefer to use a nickname, a 'screen-name', or otherwise. This information is deleted from our system when it is at least 48 hours old, so it may stay on the system for a maximum of 72 hours, as these checks run at the same time every day. Users entering their real names agree to have that information stored on our system for up to 72 hours.</p>
                <h2>Usage Data:</h2>
                <p>To improve the functionality and user experience of CheltPubGolf, we may collect and analyze anonymized usage data. This data does not personally identify you, but may include information such as device type, operating system, browser type, IP address, and usage patterns within the web app.</p>
                <h2>Cookies and Tracking Technologies:</h2>
                <p>CheltPubGolf may use cookies and similar tracking technologies to enhance your browsing experience. These technologies allow us to remember your preferences, provide personalized content, and gather anonymous statistical information. You can manage or disable cookies in your browser settings, although this may impact certain features of CheltPubGolf. For more information, please see our Cookies Policy.</p>
                <h2>Third-Party Services:</h2>
                <p>CheltPubGolf may integrate with third-party services, such as analytics providers or advertising networks, to improve our web app and deliver relevant content. These third parties may collect anonymous information about your visits to CheltPubGolf and other websites, but they do not have access to your personal information.</p>
                <h2>Data Security:</h2>
                <p>We employ appropriate security measures to protect CheltPubGolf and any data collected against unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                <h2>Children's Privacy:</h2>
                <p>CheltPubGolf is not intended for use by individuals under the age of 18. Plytol Ltd does not knowingly collect or solicit personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete that information.</p>
                <h2>Links to Third-Party Websites:</h2>
                <p>CheltPubGolf may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
                <h2>Changes to the Privacy Policy:</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised version on CheltPubGolf. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
                <h2>Contact Us:</h2>
                <p>If you have any questions or concerns about this Privacy Policy or the data practices of Plytol Ltd and CheltPubGolf, please contact us at plytol.uk@gmail.com</p>
            </span>
            <h2 className="pp-accept"> By using CheltPubGolf, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy</h2>
        </div>
    )
}
