import { React, useCallback, useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { deleteUser, getAuth, signOut } from "firebase/auth";
import { AiOutlineInstagram } from "react-icons/ai";
import Header from "components/Header";
import LeaderboardPage from "pages/main/LeaderboardPage";
import WelcomePage from "pages/main/WelcomePage";
import Footer from "components/Footer";
import ScoresheetPage from "pages/main/ScoresheetPage";
import RoutePage from "pages/main/RoutePage";
import AdminPage from "pages/main/AdminPage";
import AuthPage from "pages/auth/AuthPage";
import SetRoutePage from "pages/main/SetRoutePage";
import PPPage from "pages/legal/PPPage";
import TOSPage from "pages/legal/TOSPage";
import CookieBar from "components/CookieBar";
import CookiesPage from "pages/legal/CookiesPage";
import "./styles/style.css";

export function App() {
    const [TOSActive, setTOSActive] = useState(false);
    const [PPActive, setPPActive] = useState(false);
    const [CookiesPageActive, setCookiesPageActive] = useState(false);

    const [isNonAlc, setIsNonAlc] = useState(false);

    // state variable representing loading state of the page
    // set by async functions to visually show loading
    const [isLoading, setIsLoading] = useState(false);

    // custom hook to store session in local storage
    const useLocalStorage = (storageKey, fallBackState) => {
        const [value, setValue] = useState(JSON.parse(localStorage.getItem(storageKey)) ?? fallBackState);

        useEffect(() => {
            localStorage.setItem(storageKey, JSON.stringify(value));
        }, [value, storageKey]);

        return [value, setValue];
    }

    // state object representing current user session
    // this can be either admin or team, and is unset if there is no session
    // basically this ensures that users can stay logged in between reloads and browser restarts
    const [session, setSession] = useLocalStorage("userSession", false);

    // state object representing state of disclaimer acceptance
    // false by default, set to true if the disclaimer has been accepted
    // this ensures that the disclaimer is only shown once
    const [hasAcceptedDisclaimer, setDisclaimer] = useLocalStorage("disclaimerStorage", false);

    const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorage("cookieStorage", false);

    // callback function to validate the current login stored in session
    // this function checks the data stored in the current session against the database
    // if the data is valid and the same, it returns true
    // otherwise it returns false
    // note this is only for checking if a team login is valid - the calling method ensures admins won't be logged out
    const validateLogin = useCallback(async () => {
        setIsLoading(true);

        const data = session;

        if (!data["teamID"]) {
            // if no teamID is set, either the session is an admin or there is no session
            // since we use teamID to query the database, immediately return false

            setIsLoading(false);
            return false;
        }

        // otherwise, query the database with the given teamName
        const docSnap = await getDoc(doc(db, "Teams", data["teamID"]));
        const docObj = docSnap;
        const docID = docObj.id;

        setIsLoading(false);

        // return true if the object exists and the team name values are identical
        // the latter should always return true but it's there just in case
        return (docObj.exists() && docID === data["teamID"]);
    }, [session]);

    // state object for the page to display
    // this is here because I can't be bothered to learn React router lol
    const [page, setPage] = useState();

    // callback function to change page state
    const changeState = useCallback((pageState) => {
        switch (pageState) {
            case 0:
            default:
                setPage(<WelcomePage
                    isNonAlc={isNonAlc}
                    setIsLoading={setIsLoading}
                    changeState={changeState}
                    session={session} />);
                break;
            case 1:
                setPage(<RoutePage isNonAlc={isNonAlc} setIsLoading={setIsLoading} changeState={changeState} />);
                break;
            case 2:
                setPage(<ScoresheetPage setIsLoading={setIsLoading} changeState={changeState} setSession={setSession} />);
                break;
            case 3:
                setPage(<LeaderboardPage setIsLoading={setIsLoading} changeState={changeState} />);
                break;
            case 4:
                setPage(<AdminPage setIsLoading={setIsLoading} changeState={changeState} setSession={setSession} />)
                break;
            case 5:
                setPage(<SetRoutePage
                    setIsLoading={setIsLoading} changeState={changeState} />);
                break;
            case 6:
                setPage(<TOSPage changeState={changeState} />);
                break;
            case 7:
                setPage(<PPPage changeState={changeState} />);
                break;
        }
    }, [setSession, session, isNonAlc]);

    const signOutLocal = () => {
        setSession("");

        const auth = getAuth();
        const user = auth.currentUser;

        deleteUser(user);
        signOut(auth);
    }

    // on page load, change the state to the welcome page and validate the current session
    useEffect(() => {
        changeState(0);

        validateLogin().then(async (isValidLogin) => {
            // we can check if the current session is an admin session with the Username field
            // since team sessions do not have this field

            if (!isValidLogin) {
                // unset session if session is invalid
                setSession("");
            } else {
                // validate team name, mainly for the header
                const teamName = session["teamName"];
                const teamID = session["teamID"]

                const teamSnap = await getDoc(doc(db, "Teams", teamID));
                const remoteTeamName = teamSnap.data()["teamName"];

                if (teamName !== remoteTeamName) {
                    setSession(prevSession => {
                        return {
                            ...prevSession,
                            "teamName": remoteTeamName
                        }
                    })
                }
            }
        });
    }, [changeState, validateLogin, setSession, session]);

    // variable to store the state of the site
    // this can be either logged in (either as user or admin), in which case the corresponding content will be displayed 
    // or logged out, in which case the auth page will be shown
    let siteState;
    // if (TOSActive) {
    //     siteState = <TOSPage setTOSActive={setTOSActive} />
    // } else if (PPActive) {
    //     siteState = <PPPage changeState={changeState} />
    // } else 

    if (session) {
        // session is set
        // no need to check the validity of the session, since that's done on page load

        if (session["teamName"]) {
            // must be a team
            // set siteState to team content
            siteState = <>
                <Header changeState={changeState} />
                <div className="page--container">
                    {page}
                </div>
                <a className="instagram-link" href="https://www.instagram.com/cheltpubgolf/">
                    <div className="instagram-div">
                        <AiOutlineInstagram size={60} />
                        <h1 className="instagram-name">@CheltPubGolf</h1>
                    </div>
                </a>
                <Footer
                    changeState={changeState}
                    signOut={signOutLocal}
                    isNonAlc={isNonAlc}
                    setIsNonAlc={setIsNonAlc}
                />
            </>;
        }
    } else {
        // no session, hence show the auth screen
        siteState = <AuthPage
            setIsLoading={setIsLoading}
            setSession={setSession}
            isDisclaimer={hasAcceptedDisclaimer}
            setDisclaimer={setDisclaimer} />
    }

    return (
        <div className="container">
            {isLoading &&
                <div className="loading-screen">
                    <h1>Loading</h1>
                </div>
            }
            {TOSActive ?
                <TOSPage setTOSActive={setTOSActive} />
                :
                PPActive ?
                    <PPPage setPPActive={setPPActive} />
                    :
                    CookiesPageActive ?
                        <CookiesPage setCookiesPageActive={setCookiesPageActive} />
                        :
                        siteState}

            {(!TOSActive && !PPActive && !CookiesPageActive) &&
                <span className="footer--legal">
                    <p onClick={() => setTOSActive(true)}>Terms &amp; Conditions</p>
                    <p onClick={() => setPPActive(true)}>Privacy Policy</p>
                    <p onClick={() => setCookiesPageActive(true)}>Cookies Policy</p>
                </span>
            }
            {
                ((!hasAcceptedCookies && !CookiesPageActive)) &&
                <CookieBar setHasAcceptedCookies={setHasAcceptedCookies} setCookiesPageActive={setCookiesPageActive} />
            }
            <p className="company--footer">Plytol Ltd is a company registered England and Wales (company No. 15154920)
                Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA</p>
        </div>
    )
}
