import React, {useState} from "react";
import { FiCopy } from "react-icons/fi";

export default function CodeButton({gameCode}) {
    const [isCopied, setIsCopied] = useState(false);

    const copyCode = async () => {
        navigator.clipboard.writeText(JSON.stringify(gameCode));
        setIsCopied(true);
    }

    return (
        <>
        {
            isCopied
            ? <div className="welcome--bottom--copied">
                <h1>COPIED</h1>
            </div>
            : <div 
                className="welcome--bottom--code"
                onClick={() => copyCode()}>
                <h1>CODE</h1>
                <p>{gameCode}</p>
                <FiCopy
                    className="welcome--bottom--code--icon"    
                />
            </div>
        }
        </>
    )
}
