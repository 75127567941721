import React from "react";

export default function Footer(props) {
    return (
        <footer className="footer--container">
            <div className="footer--top">
                <span className="toggle--container">
                    <span className="footer--non-alc">
                        <input type="checkbox" onChange={() => {
                            props.setIsNonAlc(prevVal => !prevVal);
                        }} value={props.isNonAlc} />
                        <p>Non-Alcoholic?</p>
                    </span>
                </span>
                <div className="footer--signout"
                    onClick={props.signOut}>
                    SIGN OUT
                </div>
            </div>
        </footer>
    )
}
