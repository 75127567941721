import React from "react";
import { BiArrowBack } from "react-icons/bi";

export default function TOSPage({ setTOSActive }) {
    return (
        <div>
            <div className="back--button--container">
                <div className="back--container">
                    <BiArrowBack
                        size={40}
                        className="back--icon"
                        onClick={() => setTOSActive(false)} />
                </div>
            </div>
            <h1 className="tos--header">Terms &amp; Conditions</h1>
            <span className="terms--and--conditions">
                <h2>Last modified 7/10/2023</h2>
                <h2>1. Introduction</h2>
                <p>Welcome to CheltPubGolf (hereafter "the web app", "the website", "the site", etc.), a web application developed and owned by Plytol Ltd (hereafter "us", "we", or "the owners"). By accessing and using CheltPubGolf, you agree to comply with these Terms and Conditions. Please read them carefully before using the web app.</p>
                <h2>2. Liability</h2>
                <p>2.1. Plytol Ltd, as the owner of CheltPubGolf, does not accept any liability for any harm, injury, or damage caused by alcohol consumption or any activities associated with participating in the pub golf game. Users are solely responsible for their actions and decisions while using CheltPubGolf.</p>
                <p>2.2. Participants in the pub golf game acknowledge that excessive alcohol consumption can be harmful and may impair judgment, coordination, and physical abilities. It is the responsibility of each user to drink responsibly and in accordance with applicable laws and regulations.</p>
                <h2>3. Establishment Rights</h2>
                <p>3.1. Participating establishments reserve the right to refuse entry or service to any individual or team participating in the pub golf game at their discretion.</p>
                <p>3.2. Plytol Ltd shall not be held liable for any actions or decisions made by participating establishments, including but not limited to refusing entry, denying service, or any consequences arising from such actions.</p>
                <h2>4. Registered Teams</h2>
                <p>4.1. Teams participating in CheltPubGolf must register via the web app before commencing the game.</p>
                <p>4.2. Plytol reserves the right to manually remove teams and/or games without prior warning or notice.</p>
                <p>4.3. Plytol reserves the right to automatically remove registered teams from CheltPubGolf 48 hours after game creation.</p>
                <p>4.4. Removed teams will no longer have access to game features and will no longer be eligible for participation in the pub golf game.</p>
                <h2>5. Data Protection</h2>
                <p>5.1. Plytol Ltd takes user privacy and data protection seriously. We do not store personal or sensitive data provided by users through CheltPubGolf. While "team member name" fields may be filled out with a team member's full name, this is not mandatory and all users doing so automatically consent to having their name stored for at least 48 hours.</p>
                <p>5.2. We may collect and analyze anonymized usage data for the purpose of improving the web app and user experience. Such data will not be linked to individual users and will be used in accordance with our Privacy Policy.</p>
                <h2>6. Access and Eligibility</h2>
                <p>6.1. The CheltPubGolf web app is available to all users who comply with these Terms and Conditions, and are at least 18 years of age (i.e. legal drinking age in the United Kingdom).</p>
                <p>6.2. The pub golf game featured in CheltPubGolf is currently only playable in Cheltenham, (located in Gloucestershire, UK). Users outside of Cheltenham or the United Kingdom may access the web app, but do so at their own discretion. CheltPubGolf and Plytol Ltd are not responsible for any legal issues faced by users outside of Cheltenham or the United Kingdom.</p>
                <h2>7. Modifications and Termination</h2>
                <p>7.1. Plytol Ltd reserves the right to modify or terminate CheltPubGolf, including its features and availability, at any time without prior notice.</p>
                <p>7.2. Plytol Ltd may update these Terms and Conditions from time to time, and it is the user's responsibility to review them periodically.</p>
                <p>7.3. Continued use of CheltPubGolf after any modifications to the Terms and Conditions constitutes acceptance of those changes.</p>
                <h2>8. Governing Law</h2>
                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the United Kingdom of Great Britain and Northern Ireland (as of the writing of this document), without regard to its conflict of law provisions.</p>
                <h2>9. Contact Information</h2>
                <p>If you have any questions or concerns regarding CheltPubGolf or these Terms and Conditions, please contact us at plytol.uk@gmail.com</p>
            </span>
            <h2 className="tos--accept">By using CheltPubGolf, you acknowledge that you have read, understood, and agreed to these Terms and Conditions</h2>
        </div>
    )
}
