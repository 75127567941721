import { React, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { MdError } from "react-icons/md";
import "../../styles/signin.css";

export default function ReconnectPage({ setIsLoading, setPageState, setSession }) {
    // error message state variable
    const [error, setError] = useState();

    // formData state object
    const [formData, setFormData] = useState({
        "teamName": "",
        "GameCode": ""
    });

    const submitData = async (evt) => {
        evt.preventDefault();

        const sanitisedTeamName = formData["teamName"].trim();
        const sanitisedGameCode = formData["GameCode"].trim();
        const numberGameCode = parseInt(sanitisedGameCode);

        if (sanitisedTeamName.length === 0 || sanitisedGameCode.length === 0) {
            setError("Please fill out all fields");
        } else if (sanitisedGameCode.length !== 6 || isNaN(numberGameCode)) {
            setError("Invalid game code");
        } else {
            setIsLoading(true);
            setError(); // clear error

            const gamesRef = collection(db, "Games");
            const q = query(gamesRef, where("GameCode", "==", numberGameCode));
            const querySnap = await getDocs(q);
            const queryDocs = querySnap.docs;

            if (queryDocs.length === 0) {
                setError("Game does not exist");
                setIsLoading(false);
                return;
            }

            const gameObj = queryDocs[0];

            const teamsList = gameObj.data()["Teams"];
            for (let i = 0; i < teamsList.length; i++) {
                const currTeam = teamsList[i];
                if (currTeam["teamName"] === sanitisedTeamName) {
                    // team has been found, so here a failure will just break out of the loop
                    // due to either incorrect details or some team not found error
                    const teamRef = doc(db, "Teams", currTeam["teamID"]);
                    const teamSnap = await getDoc(teamRef);
                    const teamObj = teamSnap;
                    const teamData = teamObj.data();

                    if (!teamObj.exists()) {
                        setError("Invalid team");
                    } else if (teamData["gameID"] !== gameObj.id) {
                        setError("Invalid game code");
                    } else {
                        // must be fine, so log in
                        const auth = getAuth();
                        try {
                            await signInAnonymously(auth);
                        } catch (err) {
                            console.log(`${error.code}: ${error.message}`);

                            const analytics = getAnalytics();
                            logEvent(analytics, 'exception', { description: err.message });

                            return;
                        }

                        // find sum of score array
                        const score = teamData["Score"].reduce((partialSum, a) => partialSum + a, 0);

                        setSession({
                            teamID: teamObj.id,
                            teamName: sanitisedTeamName,
                            gameID: gameObj.id,
                            gameCode: gameObj.data()["GameCode"],
                            score: score,
                            isHost: teamData["isHost"]
                        });

                        const analytics = getAnalytics();
                        logEvent(analytics, "login");
                    }

                    // stop loading and return no matter the outcome
                    setIsLoading(false);
                    return;
                }
            }

            setError("Team not found");
            setIsLoading(false);
        }
    }

    const handleChange = (event) => {
        const { value, name } = event.target;

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    return (
        <div className="signin--page">
            <h1 className="signin--title">RECONNECT</h1>
            <div className="signin--form">

                <p className="signin--label">TEAM NAME</p>
                <input
                    className="signin--input"
                    id="teamname"
                    type="text"
                    name="teamName"
                    value={formData["teamName"]}
                    onChange={e => handleChange(e)}
                    maxLength="20" />
                
                <p className="signin--label">GAME CODE</p>
                <input
                    className="signin--input"
                    id="gamecode"
                    type="text"
                    name="GameCode"
                    value={formData["GameCode"]}
                    onChange={e => handleChange(e)}
                    maxLength="6" />

                {error && <h1 className="signin--error"><MdError className="signin--error--icon" /> {error}</h1>}
                <span className="signin--action--container">
                    <button
                        className="signin--submit"
                        onClick={(event) => submitData(event)}>
                        Submit
                    </button>
                    <button
                        className="signin--back"
                        onClick={() => setPageState(0)}>
                        Back
                    </button>
                </span>
            </div>
        </div>
    )
}
