import React, { useState, useEffect, useCallback } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { AiOutlineReload } from "react-icons/ai"
import BackButton from "components/BackButton";

export default function RoutePage(props) {
    const [pubData, setPubData] = useState([]);
    const fetchPubs = useCallback(async () => {
        props.setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const routeID = gameSnap.data()["RouteID"];

        const routeSnap = await getDoc(doc(db, "Routes", routeID));
        const pubsDataArr = routeSnap.data()["Pubs"];

        const storage = getStorage();

        const pubsArr = [];
        for (let i = 0; i < pubsDataArr.length; i++) {
            const currPub = pubsDataArr[i];

            const imageRefURL = `pub-images/${currPub["ImageURL"]}`;

            const imageRef = ref(storage, imageRefURL);

            const imageURL = await getDownloadURL(imageRef);

            pubsArr.push({
                ...currPub,
                "ImageURL": imageURL
            });
        }

        setPubData(pubsArr);

        props.setIsLoading(false);
    }, [props]);


    const [pubCounter, setPubCounter] = useState(0);
    const fetchPubCounter = useCallback(async () => {
        props.setIsLoading(true);

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const currentPub = gameSnap.data()["CurrentPub"];

        setPubCounter(currentPub);
    }, [props]);

    useEffect(() => {
        fetchPubs();
        fetchPubCounter();
    }, [fetchPubCounter, fetchPubs]);

    const routeComponents = pubData.map((item, index) => {
        return <div key={index} className="route--hole">
            {index < pubCounter
                ?
                <>
                    <img
                        src={item["ImageURL"]}
                        className="route--hole--image--active"
                        alt={item["PubName"]} />
                    <div className="route--hole--text--active">
                        <p>{props.isNonAlc ? item["NADrink"] : item["Drink"]}</p>
                        <p>{item["Shot"]}</p>
                        <p>Par {item["Par"] === -1 ? "n/a" : item["Par"]}</p>
                    </div>
                    {item["isWaterHazard"] &&
                        <span className="route--hole--whtext">
                            <p>WH</p>
                        </span>
                    }
                </>
                :
                <>
                    <img
                        src={item["ImageURL"]}
                        className="route--hole--image--inactive"
                        alt={item["PubName"]} />
                    <div className="route--hole--text--inactive">
                        <h1 className="route--hole--name--inactive">{item["PubName"]}</h1>
                        <p>Hole {index + 1}</p>
                    </div>
                </>
            }
        </div>;
    })

    return (
        <div>
            <div className="back--button--container">
                <BackButton changeState={props.changeState} />
            </div>
            <h1>Route</h1>
            <div className="route--holes">
                {routeComponents}
            </div>
            <button
                className="route--reload"
                onClick={() => {
                    fetchPubs();
                    fetchPubCounter();
                }}><AiOutlineReload /></button>
        </div>
    )
}
