import React, { useEffect, useCallback, useState } from "react";
import RouteTracker from "./RouteTracker";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";
import Logo from "../resources/plytol_logo.png";

export default function Header() {
    const session = JSON.parse(localStorage.getItem("userSession"));

    const [currentPub, setCurrentPub] = useState("");
    const [teamName, setTeamName] = useState("");

    const getCurrentPub = useCallback(async () => {
        const gameSnap = await getDoc(doc(db, "Games", session["gameID"]));
        const gameData = gameSnap.data();

        setCurrentPub(gameData["CurrentPub"]);
    }, [setCurrentPub, session]);

    useEffect(() => {
        setTeamName(session["teamName"]);
        getCurrentPub();
    }, [getCurrentPub, session]);

    return (
        <header className="header--container">
            <img src={Logo} alt="Plytol Ltd Logo" />

            <span className="Welcome-message-container">
                <h1>WELCOME BACK</h1>
                <h1 className="welcome--teamname">{teamName}</h1>
            </span>
            <RouteTracker CurrentPub={currentPub} />
        </header>
    )
}
