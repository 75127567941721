import React, { useCallback, useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { AiOutlineReload } from "react-icons/ai";
import BackButton from "components/BackButton";

export default function LeaderboardPage(props) {
    // state locally storing fetched team data
    const [teamData, setTeamData] = useState([]);

    // method to asynchronously fetch teams data
    const fetchTeams = useCallback(async () => {
        props.setIsLoading(true);

        // get all teams and store in fetchedData array
        // const querySnapshot = await getDocs(collection(db, "Teams"));
        // const fetchedData = querySnapshot.docs
        //     .map((doc) => ({...doc.data(), id:doc.id }));

        const session = JSON.parse(localStorage.getItem("userSession"));
        const gameRef = doc(db, "Games", session["gameID"]);
        const gameSnap = await getDoc(gameRef);
        const gameData = gameSnap.data();
        const gameTeams = gameData["Teams"];

        const fetchedData = [];
        for (let i = 0; i < gameTeams.length; i++) {
            const currTeam = gameTeams[i];

            fetchedData.push({
                "teamName": currTeam["teamName"],
                "Score": currTeam["Score"]
            });
        }

        // sort fetchedData in ascending order by score
        fetchedData.sort((a, b) => a.Score - b.Score);

        // set the sorted data as the data to display
        // sorting the data locally means we don't have to sort it later
        setTeamData(fetchedData);

        props.setIsLoading(false);
    }, [props]);

    // fetch teams once (on page load) and then never again (at least not automatically)
    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    // map the fetched team data to an array of table rows
    // these display position, which is just the index of the data + 1,
    // as well as team name and the (previously summed) score
    const tableData = teamData.map((item, index) => {
        return <tr key={index}>
            <td>{index + 1}</td>
            <td>{item["teamName"]}</td>
            <td>{item["Score"]}</td>
        </tr>
    });

    return (
        <div className="learderboard--page">
            <div className="back--button--container">
                <BackButton changeState={props.changeState} />
            </div>
            <div className="leaderboard--container">
                <span className="leaderboard--title">
                    <h1>LEADERBOARD</h1>
                    <hr />
                </span>
                <table className="leaderboard--table">
                    <thead>
                        <tr className="leaderboard--table--header">
                            <th>POSITION</th>
                            <th>TEAM</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
                <button
                    onClick={() => fetchTeams()}
                    className="leaderboard--reload">
                    <AiOutlineReload size={24} />
                </button>
            </div>
        </div>

    )
}