import React from "react";
import { BiArrowBack } from "react-icons/bi";

export default function CookiesPage({setCookiesPageActive}) {
    return (
        <div className="cookies--page">
            <div className="back--button--container">
                <div className="back--container">
                    <BiArrowBack
                        size={40}
                        className="back--icon"
                        onClick={() => setCookiesPageActive(false)} />
                </div>
            </div>
            <h1 className="tos--header">Cookie Policy</h1>
            <h2>Last updated 07/10/2023</h2>
            <p>This Cookie Policy explains how the owners of CheltPubGolf, Plytol Ltd (“we”, “us”, “our”) use cookies and similar technologies at www.cheltpubgolf.com. It explains what these technologies are and why we use them.</p>
            <p>We do not use cookies to collect personal information, or information that becomes personal information if combined with other information.</p>
            <h2>What are Cookies?</h2>
            <p>Cookies are small data files placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
            <p>Cookies set by the website owners (in this case, us) are called “first-party cookies”. Cookies set by parties other than the website owners are called “third-party cookies”. Third party cookies enable third-party features or functionality on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognise your computer both when it visits the website in question and also when it visits certain other websites.</p>
            <h2>Why do we use cookies?</h2>
            <p>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as “essential” or “strictly necessary” cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience of our online properties. Third parties server cookies through our website for analysing, analytics, and other purposes. This is described in more detail below.</p>
            <h2>How do we use cookies?</h2>
            <p>We use Google Analytics in order to constantly improve your browsing experience on our website. Please see Google's privacy and terms <a href="https://policies.google.com/technologies/partner-sites">here</a>.</p>
            <h2>How can I control and delete cookies?</h2>
            <p>We will not use cookies to collect personally identifiable information about you. However, should you choose to disable, reject, or block our cookies, some parts of our website may not function fully, or in some cases, the website may not be accessible at all.</p>
            <p>The specific types of first- and third-party cookies served through our website and the purposes they perform are described below - please note that the specific cookies served may vary depending on the specific online properties you visit:</p>
            <h2>Essential Cookies</h2>
            <table className="cookies--table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Purpose</th>
                        <th>Expires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>userSession</td>
                        <td>Used to keep you signed in</td>
                        <td>N/a, deleted when user signs out</td>
                    </tr>
                    <tr>
                        <td>disclaimerStorage</td>
                        <td>Stores the user's acceptance of the disclaimer upon first entering the website</td>
                        <td>N/a</td>
                    </tr>
                    <tr>
                        <td>cookieStorage</td>
                        <td>Stores the user's acceptance of the cookie policy</td>
                        <td>N/a</td>
                    </tr>
                </tbody>
            </table>
            <h2>Analytics Cookies</h2>
            <table className="cookies--table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Purpose</th>
                        <th>Expires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>_ga_10F2G5THDP</td>
                        <td>Used by Google Analytics to persist session state</td>
                        <td>1 year 1 month</td>
                    </tr>
                    <tr>
                        <td>_ga</td>
                        <td>Used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the site's analytics reports</td>
                        <td>1 year 1 month</td>
                    </tr>
                </tbody>
            </table>
            <h2 className="tos--accept">By using CheltPubGolf, you acknowledge that you have read, understood, and agreed to our Cookie Policy</h2>
        </div>
    )
}
