import React from "react";
import { BiArrowBack } from "react-icons/bi";

export default function BackButton(props) {
    return (
        <div className="back--container">
            <BiArrowBack
                size={40}
                className="back--icon"
                onClick={() => props.changeState(0)} />
        </div>
    )
}
