import React, {useState} from "react";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { MdError } from "react-icons/md";

export default function AdminSignIn({setIsLoading, setPageState, setSession}) {
    // error message state variable
    const [error, setError] = useState();

    // state object representing form data
    const [loginData, setLoginData] = useState({
        "Username": "",
        "Password": ""
    });
    
    // method to handle form data
    const handleLoginData = (event) => {
        const {name, value} = event.target;

        setLoginData(prevLoginData => {
            return {
                ...prevLoginData,
                [name]: value
            };
        });
    };

    // function to sanitise and verify login details
    const verifyLogin = async() => {
        const sanitisedUsername = loginData["Username"].trim().toLowerCase();
        const sanitisedPassword = loginData["Password"].trim();
        
        if(!sanitisedUsername || !sanitisedPassword) {
            setError("Username or password cannot be empty");
            return;
        }

        setIsLoading(true);

        const q = query(collection(db, "Admins"),
            where("userName", "==", sanitisedUsername),
            where("password", "==", sanitisedPassword));
        const querySnapshot = await getDocs(q);

        if(querySnapshot.empty) {
            // no admin account found with matching details, so reject
            setError("Invalid login details");
        } else {
            // else, accept and set session
            setSession({
                "userName": sanitisedUsername
            });
            setError("");
        }
        
        setIsLoading(false);
    }
    
    return (
        <div className="admin--signin--container">
            <div className="admin--signin--form">
                <input
                    type="text"
                    value={loginData["Username"]}
                    onChange={handleLoginData}
                    name="Username"
                    placeholder="Username"
                />
                <input 
                    type="password"
                    value={loginData["Password"]}
                    onChange={handleLoginData}
                    name="Password"
                    placeholder="Password"
                />
                {error && <h1 className="signin--error"><MdError className="signin--error--icon" /> {error}</h1>}
                <button onClick={() => verifyLogin()}>Log in</button>
                <button onClick={() => setPageState(0)}>Back</button>
            </div>
        </div>
    )
}
