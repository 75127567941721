import React, { useState } from "react";
import { db } from "../../firebase";
import { setDoc, doc, collection, query, where, getDocs } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { MdError } from "react-icons/md"
import Logo from "../../resources/plytol_logo.png";
import "../../styles/signin.css";

export default function JoinGameSignUp({ setIsLoading, setPageState, setSession }) {
    // error message state variable
    const [error, setError] = useState();

    // number of players state variable
    // set to 2 initially
    const [numPlayers, setNumPlayers] = useState(2);

    // formData state object
    const [formData, setFormData] = useState({
        "teamName": "",
        "Players": ["", "", "", ""],
        "GameCode": "",
        "isAdmin": false
    });

    const submitData = async (evt) => {
        evt.preventDefault();

        // array of non-null player names
        const validPlayers = [];
        formData["Players"].forEach((item) => {
            const sanitisedItem = item.trim();
            if (sanitisedItem) {
                validPlayers.push(sanitisedItem);
            }
        });

        const sanitisedTeamName = formData["teamName"].trim();
        const sanitisedGameCode = formData["GameCode"].trim();
        const gameCodeNumber = parseInt(sanitisedGameCode);

        if (validPlayers.length < 2) {
            setError("Must choose at least 2 players");
        } else if (validPlayers.length !== numPlayers) {
            setError("Cannot have empty player names");
        } else if (sanitisedTeamName === "") {
            setError("Please enter team name");
        } else if (isNaN(gameCodeNumber) || sanitisedGameCode.length !== 6) {
            setError("Please enter a valid game code");
        } else {
            // otherwise valid
            setError(); // unset error for next time

            setIsLoading(true);

            try {
                // initialise array of scores as array of ten 0s
                // this array is indexed by (hole - 1)
                const scoreArr = [];
                for (let i = 0; i < 10; i++) {
                    scoreArr.push(0);
                }

                const gamesRef = collection(db, "Games");
                const q = query(gamesRef, where("GameCode", "==", gameCodeNumber));
                const querySnap = await getDocs(q);
                const queryObj = querySnap;
                const currentGame = queryObj.docs[0].data();
                const gameID = queryObj.docs[0].id;
                const teamsList = currentGame["Teams"];

                // check all teams of the game for name conflicts
                for (let i = 0; i < teamsList.length; i++) {
                    const existingTeamName = teamsList[i]["teamName"];

                    if (existingTeamName === sanitisedTeamName) {
                        setError("Team name is already taken for this game");
                        setIsLoading(false);
                        return;
                    }
                }

                // else, should be fine to add this team
                const auth = getAuth();
                try {
                    await signInAnonymously(auth);
                } catch (err) {
                    console.log(`${error.code}: ${error.message}`);

                    const analytics = getAnalytics();
                    logEvent(analytics, 'exception', { description: err.message });

                    return;
                }

                const newTeamRef = doc(collection(db, "Teams"));
                const teamID = newTeamRef.id;

                await setDoc(newTeamRef, {
                    teamName: sanitisedTeamName,
                    Players: validPlayers,
                    Score: scoreArr,
                    PenaltyPoints: 0,
                    gameID: gameID,
                    isHost: false
                });

                // need to update the current game
                teamsList.push({
                    "teamID": teamID,
                    "teamName": sanitisedTeamName,
                    "Score": 0,
                    "PenaltyPoints": 0
                });

                await setDoc(doc(db, "Games", gameID), {
                    "Teams": teamsList
                }, { merge: true });

                // also need to set session
                setSession({
                    teamID: teamID,
                    teamName: sanitisedTeamName,
                    gameID: gameID,
                    gameCode: currentGame["GameCode"],
                    score: 0,
                    isHost: false
                });

                const analytics = getAnalytics();
                logEvent(analytics, 'sign_up', {
                    type: "join"
                });

                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                console.log(err);

                const analytics = getAnalytics();
                logEvent(analytics, 'exception', { description: err.message });
            }
        }
    }

    // function to handle changing form data
    const handleChange = (event) => {
        const { value, name } = event.target;

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        });
    }

    // specifically handle the changing of player name data
    function handlePlayer(event, index) {
        const { value, name } = event.target;

        const newPlayers = formData["Players"].map((player, i) => {
            if (i === index) {
                return value;
            } else {
                return player;
            }
        });

        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: newPlayers
            }
        });
    }

    const textboxes = [];
    for (let i = 0; i < numPlayers; i++) {
        textboxes.push(
            <React.Fragment key={i}>
                <p className="signin--label">PLAYER {i + 1}</p>
                <input
                    className="signin--input"
                    key={i}
                    id={`player${i + 1}`}
                    name="Players"
                    value={formData["Players"][i]}
                    type="text"
                    onChange={e => handlePlayer(e, i)}
                    maxLength="20"
                />
                <p className="input--limit--text">{formData["Players"][i].length}/20</p>
            </React.Fragment>
        );
    }

    const changePlayerNum = (val) => {
        const playerLowerBound = 2;
        const playerUpperBound = 4;

        if ((numPlayers + val) < playerLowerBound || (numPlayers + val) > playerUpperBound) {
            // do nothing
            // this should never fire but it's here just in case
            setError("Number of players must be between 2 and 4")
        } else {
            setFormData(prevFormData => {
                let newPlayers = prevFormData["Players"];
                newPlayers[numPlayers + val] = ""; // unset any previously set (now invalid) data
                return {
                    ...prevFormData,
                    Players: newPlayers
                }
            });

            // set this after since previous relies on prevNum
            // so there'd be a race condition otherwise
            setNumPlayers(prevNum => prevNum + val);
        }
    }

    return (
        <div className="signin--page">
            <img src={Logo} alt="Plytol Ltd Logo" />
            <h1 className="signin--title">JOIN GAME</h1>
            <div className="signin--form">
                {textboxes}
                <span className="signin--numbuttons">
                    {
                        numPlayers < 4 &&
                        <button
                            onClick={() => changePlayerNum(1)}
                            disabled={numPlayers >= 4}>
                            Add Player
                        </button>
                    }
                    {
                        numPlayers > 2 &&
                        <button
                            onClick={() => changePlayerNum(-1)}
                            disabled={numPlayers <= 2}>
                            Remove Player
                        </button>
                    }
                </span>
                <p className="signin--label">TEAM NAME</p>
                <input
                    className="signin--input"
                    id="teamname"
                    type="text"
                    name="teamName"
                    value={formData["teamName"]}
                    maxLength="20"
                    onChange={e => handleChange(e)} />
                <p className="input--limit--text">{formData["teamName"].length}/20</p>

                <p className="signin--label">GAME CODE</p>
                <input
                    className="signin--input"
                    id="gamecode"
                    type="text"
                    name="GameCode"
                    value={formData["GameCode"]}
                    onChange={e => handleChange(e)}
                    maxLength="6" />

                {error && <h1 className="signin--error"><MdError className="signin--error--icon" /> {error}</h1>}

                <span className="signin--action--container">
                    <button
                        className="signin--submit"
                        onClick={(event) => submitData(event)}>
                        Submit
                    </button>
                    <button
                        className="signin--back"
                        onClick={() => setPageState(0)}>
                        Back
                    </button>
                </span>
            </div>

        </div>
    )
}
